<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getBusinesses"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
<!--              <b-btn class="btn btn-sm btn-success mr-4" @click="showCreateLeadModal = true">Create Customer</b-btn>
              <router-link v-if="routeName !== 'agency.businesses.index'" :to="{ name: 'agency.businesses.index', query: { 'create_customer': true } }" class="btn btn-sm btn-info mr-4">Create Customer Manually</router-link>-->
              <vue-json-to-csv :json-data="customers" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getBusinesses"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="table-responsive mb-0 customer-table">
                <b-table
                  :items="customers"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  @sort-changed="sortChanged"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(active)="data">
                    <span v-if="data.item.active">Active</span>
                    <span v-else>Inactive</span>
<!--                    <b-button
                        variant="primary"
                        :disabled="loadingImpersonate"
                        @click="impersonate(data.item.id)"
                    >
                      <b-spinner v-if="loadingImpersonate" small />
                      <span v-else
                      >Log in <i class="uil uil-sign-out-alt"></i
                      ></span>
                    </b-button>-->
                  </template>
                  <template v-slot:cell(connected)="data">
                    <span v-if="data.item.location" class="text-success font-size-20"><i class="uil uil-check" /></span>
                    <span v-else class="text-danger font-size-20"><i class="uil uil-times" /></span>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalCustomers }} customers
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalCustomers"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete Customer">
      <p v-if="selectedBusiness"
        >Are you sure you want to delete business "{{
          selectedBusiness.business_name
        }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleRemove">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showCreateLeadModal" size="lg" :hide-footer="true" :hide-header="true" modal-class="widget-modal">
      <div class="text-center">
        <h4>Create Lead</h4>
        <p>
          <reply-engine-generator
              ref="rlg"
              :search-text="widgetDefaultText"
              class="widget-creator"
              :agency-id="user.team_agency"
              :starting-step="1"
              header-text="Search For Your Business"
              background-color="#ffffff"
              main-text-color="#222222"
              primary-button-background-color="#34a853"
              primary-button-text-color="#ffffff"
              place-holder="Search"
              :manually-created="true"
              button-text="Create Lead"
              :force-busy="createIsBusy"
              @prospectCreated="prospectCreated"
          ></reply-engine-generator>
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
import  ReplyEngineGenerator from '../../../embed-form/src/components/Form'
export default {
  components:{
    VueJsonToCsv,
    'reply-engine-generator': ReplyEngineGenerator
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'id', label: '#', sortable: true },
          { key: 'small_date', label: 'Date', sortable: true },
          { key: 'business_name', label: 'Business', sortable: true },
          { key: 'full_name', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'connected', label: 'Connected', class: 'text-center'},
          { key: 'phone_number', label: 'Phone', sortable: true },
          { key: 'active', label: 'Status', sortable: true },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          prospect_status:'all',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      showDeleteModal: false,
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showWelcomeModal: false,
      createIsBusy:false,
      widgetDefaultText:'',
      showCreateLeadModal:false,
      showShippingModal:false,
      shippingModalOwner: {
        first_name:'',
        last_name:'',
        id:null,
        tracking_number:'',
        working:false,
        tracking_numbers:[]
      }
    }
  },

  computed: {
    customers() {
      return this.$store.getters['customers/all'] || []
    },

    totalCustomers() {
      return this.$store.getters['customers/total']
    },

    reload() {
      return this.$store.getters['customers/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    routeName(){
      return this.$route.name;
    }
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('customers/setCurrentPage', 'index')
  },

  mounted() {
    this.getBusinesses();


    if(window.location.search.includes('create_lead')){
      this.showCreateLeadModal = true;
      let query = Object.assign({}, this.$route.query);
      delete query.create_lead;
      if(query.hasOwnProperty('business_name')) {
        this.widgetDefaultText = decodeURI(query.business_name);
        delete query.business_name;
      }
      this.$router.replace({ query });
    }
  },

  methods: {
    prospectCreated(business){
      this.showCreateLeadModal = false;
      this.createIsBusy = false;
      this.$router.push({
        name: 'agency.businesses.edit',
        params: { id: business.id },
      })
    },
    csvTitle(){
      return 'reply-engine-export-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true
      let queries = this.datatable.queries;
      queries.agency_id = this.user.team_agency;
      this.$store
        .dispatch('customers/getAll', {
          queries: this.datatable.queries,
          page: 'index'
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },

    impersonate(businessId) {
      this.loadingImpersonate = true
      const teamId = this.user.teams.find(team => team.business_id === businessId)?.id || null;
      this.$store
        .dispatch('auth/impersonateTeam', teamId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },

    remove(business) {
      this.showDeleteModal = true
      this.selectedBusiness = business
    },

    handleRemove() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedBusiness.id
      this.$store
        .dispatch('business/archive', this.selectedBusiness.id)
        .then(() => {
          this.loadingRemove = null
          this.getBusinesses()
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  .status-filter{
    text-transform: capitalize;
  }
</style>
<style>
.pac-container.pac-logo {
  z-index: 10000000;
}
.customer-table td.actions {min-width:200px; max-width: 200px;}
</style>
